$padding-unit: 32px;
$blue: #3f51b5;
$light-gray: #d2d2d2;
$bright-navy: #122272;
$body-font-family: 'Lato', 'Helvetica Neue', 'Helvetica', Helvetica, Arial,
    sans-serif;
$green: #45d288;
$font-size-standard: 1rem; // 16px
$dark-blue: #333645;
$font-size-m: 1.125rem; // 18px
$font-size-l: 1.3125rem; // 21px
$font-size-xl: 1.5rem; // 24px

#home-map {
    position: relative;
    width: 800px !important;

    .map-container {
        height: 720px;
    }

    .map-parent-container {
        width: 800px !important;

        .leaflet-control-attribution {
            display: none;
        }
    }

    .loading-small {
        height: 100%;
        margin-bottom: 0;

        .fa-spinner {
            top: 50%;
            position: relative;
        }
    }

    .legend {
        background-color: white;
        border-radius: 5px;
        border: 1px solid $light-gray;
        padding: 5px;

        h6 {
            font-size: 12px;
            margin: 0;
        }

        > div:before {
            content: '';
            display: inline-block;
            margin-right: 5px;
            width: 15px;
            height: 15px;
            border-radius: 15px;
            border: 2px solid gray;
            position: relative;
            box-sizing: border-box;
            top: 3px;
        }

        .endemic:before {
            background-color: rgba(red, 0.5);
            border-color: red;
        }

        .non-endemic:before {
            background-color: rgba(green, 0.5);
            border-color: green;
        }

        .not-tested:before {
            background-color: rgba(grey, 0.5);
            border-color: grey;
        }

        .infos {
            font-size: 10px;
            margin-top: 5px;

            &:before {
                display: none;
            }
        }
    }
}

@mixin white-text-dark-background {
    color: white;
    -webkit-font-smoothing: antialiased;
}

@mixin text--center {
    text-align: center;
}

/* landing page! this is all static (hard-coded) content */

/* nothing here is very re-useable so it's not particularly elegant css */

/* styles common to all sections */

/* Encapsulated in this id to avoid leaking this CSS to the main Iaso app */
#trypelim-home {
    [class^='section--feature'] {
        display: flex;
        padding: $padding-unit * 1.5 $padding-unit;
        box-sizing: border-box;

        img {
            display: block;
        }
    }

    /* specific section styles */

    .section--feature {
        &--welcome {
            flex-direction: column;
            background: white;
        }

        &--pilot-area {
            background: $blue
                linear-gradient(-180deg, rgba(black, 0.2) 0%, transparent 100%);
            padding-top: 0;
            padding-bottom: 0;
            padding-right: 0;
            min-height: 720px;
            /* height of map */
        }

        &--reports {
            background: $light-gray;
        }

        &--suspect {
            background: white;
        }

        &--partners {
            flex-direction: column;
            background-color: $bright-navy;
            background-image: radial-gradient(
                at top,
                rgba(white, 0.2) 0%,
                $bright-navy 100%
            );
            @include white-text-dark-background;

            h2 {
                @include text--center;
                margin-bottom: 1.5em;
            }
        }

        @media (max-width: 500px) {
            &--pilot-area,
            &--reports {
                display: block;

                #home-map {
                    .map-container {
                        height: 400px;
                    }

                    .map-parent-container {
                        width: calc(50% + #{$padding-unit}) !important;
                        position: relative;
                        left: -$padding-unit;

                        .leaflet-control-container {
                            display: none;
                        }
                    }

                    .loading-small {
                        height: 400px;
                        width: calc(100% + #{$padding-unit}) !important;
                        position: relative;
                        left: -$padding-unit;

                        .fa-spinner {
                            top: 50%;
                            position: relative;
                            right: 24%;
                        }
                    }
                }

                .section__content__image--reports {
                    width: 100%;
                    min-width: 100%;
                    min-height: 0;

                    img {
                        width: 100%;
                        margin-bottom: $padding-unit;
                    }
                }
            }

            &--reports {
                text-align: center;
            }
        }
    }

    /* common styles for content */

    [class^='section__content'] {
        h2 {
            line-height: 1.25;
            margin-bottom: 1.25em;
        }

        [class^='button'] {
            margin-top: 1.5em;
            display: inline-block;
            width: auto;
            padding: 0.75em 1.5em;
            border: none;
            box-shadow: inset 0 -2px 0 0 rgba(black, 0.2);
            border-radius: 4px;
            background: $green;
            color: white;
            font-family: $body-font-family;
            font-size: $font-size-standard;
            font-weight: bold;
            line-height: 1;
            text-decoration: none;
            -webkit-font-smoothing: antialiased;
            transition: all 0.25s cubic-bezier(0.25, 0.46, 0.45, 0.94);
            cursor: pointer;
            &:hover {
                background: darken($green, 10%);
            }
        }
    }

    .section__content {
        &--welcome {
            @include text--center;

            h1 {
                margin-bottom: 1em;
            }

            p {
                max-width: none;
                padding: 0 20%;
            }
        }

        &--pilot-area {
            align-self: flex-start;
            @include white-text-dark-background;
            max-width: 32em;
            padding: $padding-unit $padding-unit/2 $padding-unit 0;

            h2 {
                margin-top: 1.5em;
                margin-bottom: 0.5em;

                &:first-of-type {
                    margin-top: 0;
                }
            }

            p {
                margin-bottom: 1.5em;
            }
        }

        &__image--pilot-area {
            order: 2;
            margin-left: auto;
        }

        &--reports {
            align-self: center;
            flex-basis: 50%;

            p {
                max-width: 26em;
            }
        }

        &__image--reports {
            min-height: 268px;
            width: 50%;
            min-width: 509px;
        }

        &__heading--data {
            order: 2;
            flex-basis: 50%;
            @include text--center;

            h2 {
                margin-bottom: 4em;
            }

            img {
                display: initial;
                margin-bottom: 1em;
            }
        }

        &--suspect {
            flex: 1;
            text-align: center;

            p {
                max-width: inherit;
            }
        }

        &--partners {
            display: flex;
            justify-content: center;
        }
    }

    .section__list--partners {
        max-width: 20em;

        li {
            font-weight: bold;
            margin-bottom: 1em;
            line-height: 1.25;
        }
    }

    .welcome__images {
        img {
            width: 150px;
            display: inline;
            vertical-align: middle;
            margin: 0 2em 1em;
        }
    }

    .home-container {
        font-family: $body-font-family;
        color: $dark-blue;

        .section--feature--bar-chart {
            min-height: 37vw;
            background-color: white;
            display: block;

            > section,
            h2 {
                max-width: 80%;
                margin: 0 auto;
            }

            .loading-small {
                width: 100%;
                height: 100%;
                min-height: 30vw;
                display: flex;
                justify-content: center;
                align-items: center;
            }
        }

        h1,
        h2,
        h3,
        h4,
        h5,
        h6 {
            font-weight: bold;
        }

        h1 {
            font-size: $font-size-xl;
        }

        h2 {
            font-size: $font-size-l;
        }

        h3 {
            font-size: $font-size-m;
        }

        h4,
        h5,
        h6 {
            font-size: $font-size-standard;
        }

        p {
            line-height: 1.4;
            margin-bottom: 1em;
            &:last-of-type {
                margin-bottom: 0;
            }
        }
    }

    .home-zones {
        cursor: default !important;
        stroke-width: 2;
        fill-opacity: 0.6;
    }

    .home-provinces {
        fill: transparent;
        fill-opacity: 0;
        stroke-dasharray: 1, 0;
        stroke-opacity: 1;
        stroke: #935116;
        stroke-width: 2;
        cursor: default !important;
    }
}
